import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpService } from './http/http.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionsService {
  private path =
    environment.production || environment.beta || environment.staging
      ? 'functions'
      : 'http://localhost:8202/squadcast-dev/us-central1';

  constructor(private httpService: HttpService, private httpClient: HttpClient) {}

  async get<T>(fn: string, queryParams?: object) {
    const paramsURL = new URLSearchParams(Object.entries(queryParams || {})).toString();
    return this.httpService.get<T>(`${this.path}/${fn}?${paramsURL}`);
  }

  async post<T>(fn: string, body: object) {
    return this.httpService.post<T>(`${this.path}/${fn}`, body);
  }

  async delete<T>(fn: string, body: object) {
    return this.httpService.delete<T>(`${this.path}/${fn}`, body);
  }

  async postAnon<T>(fn: string, body: object) {
    return firstValueFrom(this.httpClient.post<T>(`${this.path}/${fn}`, body));
  }
}
